import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Button,
    Alert,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    InputGroup,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {API_ROOT} from '../../api-config';
import classnames from 'classnames';
import Swal from 'sweetalert2'
import {getProductsParent, getOwners, getBrands, getTypes, getFactors} from '../Common/DataFunctions.js';

import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';

var money = require("money-math");

class PlaceOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account_type: 1,
            cart: {
                "owners": {},
                "subtotal": 0,
                "tax": 0,
                "deposit": 0,
                "total": 0,
                "credit": 0,
                "num_promo": 0
            },
            delivery_instructions: '',
            notes: "",
            loading: true,
            processing: false,
            products: {},
            dropdown_print: {
                owner: "All",
                brand: "All",
                type: "All",
                factor: "All"
            },
            filter: {
                owner: null,
                brand: null,
                type: null,
                factor: null
            },
            list: {
                type: [],
                brand: [],
                owner: [],
                factor: [],
            },
            products_company: {},
            product_names: {},
            product_parent_id: null,
            product_sort: [],
            payment_method: '1',
            saved_number: '',
            saved_name: '',
            saved_expiry: '',
            saved_issuer: '',
            saved_card_id: '',
            credit: 0,
            allow_cod: 0,
            allow_delayed_cc:0,
            pricing_category: 0
        };
        this.updateCart = this.updateCart.bind(this)
    }

    componentDidMount() {
        document.title = "Place an Order | Equals";
        this.getAccountInfo();
        this.getSavedCC();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.active_account !== prevProps.active_account) {
            this.getSavedCC();
            this.getAccountInfo();
        }
    }

    getSavedCC() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + this.props.active_account + '/saved_card')
            .then(function (response) {
                self.setState({
                    saved_name: response.data.name,
                    saved_number: '****' + response.data.last_four,
                    saved_expiry: response.data.expiry_month + "/" + response.data.expiry_year,
                    saved_issuer: response.data.card_type,
                    saved_card_id: response.data.card_id,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    updateCart(cart) {
        this.setState({cart: cart})
    }

    getAccountInfo() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + this.props.active_account)
            .then(function (response) {
                var pricing_category = 1;
                if (response.data.account_type == 3) { // LCBO
                    pricing_category = 2;
                } else if (response.data.account_type == 6) { // Agency
                    pricing_category = 3;
                } else if (response.data.account_type == 7) { // LCBO Warehouse
                    pricing_category = 4;
                } else if (response.data.account_type == 8) { // NSLC
                    pricing_category = 5;
                } else if (response.data.account_type == 9) { // Cat B
                    pricing_category = 9;
                }
                self.setState({
                    account_type: response.data.account_type,
                    province: response.data.province,
                    credit: response.data.credit,
                    delivery_instructions: response.data.delivery_instructions,
                    allow_cod: response.data.allow_cod,
                    allow_delayed_cc: response.data.allow_delayed_cc,
                    pricing_category: pricing_category
                });
                getOwners(function (owners) {
                    var list = self.state.list;
                    list.owner = owners;
                    getBrands(function (brands) {
                        list.brand = brands;
                        getTypes(function (types) {
                            list.type = types;
                            getFactors(function (factor) {
                                list.factor = factor;
                                var product_names = {};
                                getProductsParent(function (products) {
                                    var product_sort = {};
                                    Object.keys(products).map(function (product_owner_id, key) {
                                        var owner_sort = []
                                        Object.keys(products[product_owner_id]).map(function (product_parent_id, key) {
                                            var factors = [];
                                            var num_products = 0;
                                            Object.keys(products[product_owner_id][product_parent_id].products).map(function (product_id) {
                                                if (
                                                    typeof products[product_owner_id][product_parent_id].products[product_id].pricing[pricing_category] != "undefined" &&
                                                    products[product_owner_id][product_parent_id].products[product_id].pricing[pricing_category].price != null &&
                                                    products[product_owner_id][product_parent_id].products[product_id].is_deleted == 0 &&
                                                    (localStorage.getItem('staff_id') > 0 || products[product_owner_id][product_parent_id].products[product_id].is_internal == 0)
                                                ) {
                                                    num_products++;
                                                    product_names[product_id] = products[product_owner_id][product_parent_id].products[product_id].name;
                                                    products[product_owner_id][product_parent_id].products[product_id].quantity = 0;
                                                    products[product_owner_id][product_parent_id].products[product_id].quantity_promo = 0;
                                                    factors.push(products[product_owner_id][product_parent_id].products[product_id].product_factor_id);
                                                }
                                            });
                                            if (num_products == 0) {
                                                delete products[product_owner_id][product_parent_id];
                                            } else {
                                                products[product_owner_id][product_parent_id].factors = factors;
                                                products[product_owner_id][product_parent_id].visible = false;
                                                owner_sort.push({id: product_parent_id, name: products[product_owner_id][product_parent_id].name})
                                            }
                                        });
                                        owner_sort = owner_sort.sort((a, b) => {
                                            if (a.name < b.name) {
                                                return -1;
                                            }
                                            if (a.name > b.name) {
                                                return 1;
                                            }
                                            return 0;
                                        });
                                        product_sort[product_owner_id] = owner_sort;
                                    });
                                    if (Object.keys(products).length > 0) {
                                        self.setState({
                                            products: products,
                                            product_names: product_names,
                                            product_sort: product_sort,
                                            list: list,
                                            loading: false
                                        });
                                    }
                                });
                            })
                        });
                    });
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    changeDropdown = (value, key) => {
        var dropdown_print = this.state.dropdown_print;
        if (value == null) {
            dropdown_print[key] = "All"
        } else {
            dropdown_print[key] = this.state.list[key][value].name;
        }
        var filter = this.state.filter;
        filter[key] = value;
        this.setState({
            filter: filter,
            dropdown_print: dropdown_print
        });
    };

    toggleProductParent(product_owner_id, key) {
        var products = this.state.products;
        products[product_owner_id][key].visible = !products[product_owner_id][key].visible;
        this.setState({
            products: products
        })
    }

    toggleExpand(visible) {
        var products = this.state.products;
        Object.keys(products).map(function (product_parent_id) {
            products[product_parent_id].visible = visible;
        });
        this.setState({
            products: products
        })
    }

    ChangeNotes = (event) => {
        this.setState({notes: event.target.value});
    };

    ChangeInstructions = (event) => {
        this.setState({delivery_instructions: event.target.value});
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/account/' + this.props.active_account, {delivery_instructions: event.target.value})
    };

    toggleTab = tab => {
        if (this.state.payment_method !== tab) {
            this.setState({
                payment_method: tab
            });
        }
    };

    submitOrder = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/order/new/' + this.props.active_account, self.state)
            .then(function (response) {
                localStorage.setItem('show_success' + response.data.order_id, true);
                self.props.history.push("/order/" + response.data.order_id);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        processing: false
                    });
                }
            });
    };

    render() {
        var pricing_category = 1;
        if (this.state.account_type == 3) { // LCBO
            pricing_category = 2;
        } else if (this.state.account_type == 6) { // Agency
            pricing_category = 3;
        } else if (this.state.account_type == 7) { // LCBO Warehouse
            pricing_category = 4;
        } else if (this.state.account_type == 8) { // NSLC
            pricing_category = 5;
        } else if (this.state.account_type == 9) { // Cat B
            pricing_category = 9;
        }
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Place an Order</div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className="card-body">
                        <div className={(this.state.credit > 0 ? "alert alert-info text-center" : "d-none")}>
                            <i className="far fa-thumbs-up fa-lg mr-1"></i> Goods news! You have a ${this.state.credit} account credit. This will be
                            automatically applied to your next order.
                        </div>
                        <p className="lead mb-0">Select Item(s)</p>
                        <div className={(this.state.loading ? "invisible" : "")}>
                            <div className="mb-2 mt-1">
                                <Button color="secondary" size="xs" className="mr-1" style={{fontSize: '13px'}} onClick={this.toggleExpand.bind(this, true)}>
                                    <i className="fas fa-chevron-down"></i> Expand All
                                </Button>
                                <Button color="secondary" size="xs" style={{fontSize: '13px'}} onClick={this.toggleExpand.bind(this, false)}>
                                    <i className="fas fa-chevron-up"></i> Collapse All
                                </Button>
                                <div className="form-inline float-right">
                                    Filter:
                                    <InputGroup>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret size="xs"
                                                            className={this.state.filter.owner > 0 ? "text-left ml-1 border-info" : "text-left ml-1"}
                                                            style={{fontSize: '13px', width: 'auto'}}>
                                                <span className="text-muted small">COMPANY:</span> {this.state.dropdown_print.owner}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => this.changeDropdown(null, "owner")}>All</DropdownItem>
                                                {Object.keys(this.state.list.owner).map(function (owner_id) {
                                                    if (this.state.list.owner[owner_id].active_products > 0) {
                                                        return (
                                                            <DropdownItem key={owner_id}
                                                                          onClick={() => this.changeDropdown(owner_id, "owner")}>{this.state.list.owner[owner_id].name}</DropdownItem>
                                                        )
                                                    }
                                                }, this)}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </InputGroup>
                                    <InputGroup>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret size="xs"
                                                            className={this.state.filter.type > 0 ? "text-left ml-1 border-info" : "text-left ml-1"}
                                                            style={{fontSize: '13px', width: 'auto'}}>
                                                <span className="text-muted small">TYPES:</span> {this.state.dropdown_print.type}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => this.changeDropdown(null, "type")}>All</DropdownItem>
                                                {Object.keys(this.state.list.type).map(function (type_id) {
                                                    return (
                                                        <DropdownItem key={type_id}
                                                                      onClick={() => this.changeDropdown(type_id, "type")}>{this.state.list.type[type_id].name}</DropdownItem>
                                                    )
                                                }, this)}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </InputGroup>
                                    <InputGroup>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret size="xs"
                                                            className={this.state.filter.factor > 0 ? "text-left ml-1 border-info" : "text-left ml-1"}
                                                            style={{fontSize: '13px', width: 'auto'}}>
                                                <span className="text-muted small">FORMAT:</span> {this.state.dropdown_print.factor}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => this.changeDropdown(null, "factor")}>All</DropdownItem>
                                                {Object.keys(this.state.list.factor).map(function (factor_id) {
                                                    return (
                                                        <DropdownItem key={factor_id}
                                                                      onClick={() => this.changeDropdown(factor_id, "factor")}>{this.state.list.factor[factor_id].name}</DropdownItem>
                                                    )
                                                }, this)}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </InputGroup>
                                    <InputGroup>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret size="xs"
                                                            className={this.state.filter.brand > 0 ? "text-left ml-1 border-info" : "text-left ml-1"}
                                                            style={{fontSize: '13px', width: 'auto'}}>
                                                <span className="text-muted small">BRAND:</span> {this.state.dropdown_print.brand}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => this.changeDropdown(null, "brand")}>All</DropdownItem>
                                                {Object.keys(this.state.list.brand).map(function (brand_id) {
                                                    return (
                                                        <DropdownItem key={brand_id}
                                                                      onClick={() => this.changeDropdown(brand_id, "brand")}>{this.state.list.brand[brand_id].name}</DropdownItem>
                                                    )
                                                }, this)}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className={(!this.state.loading ? "table table-hover table-pointer table-bordered text-left" : "d-none")}>
                                    <thead className="thead-dark">
                                    <tr>
                                        <th>Product</th>
                                        <th className="d-lg-table-cell">Type</th>
                                        <th className="d-lg-table-cell">Format(s)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.keys(this.state.products).map((product_owner_id, key1) => {
                                        var self = this;
                                        return (
                                            <React.Fragment key={key1}>
                                                <tr>
                                                    <td colSpan='4' className="h4 text-bold">{self.state.list.owner[product_owner_id].name}</td>
                                                </tr>
                                                {self.state.product_sort[product_owner_id].map(function (product_parent, key) {
                                                    var product_parent_id = product_parent['id'];
                                                    var formats_print = "";
                                                    var formats = [];
                                                    var parent = self.state.products[product_owner_id][product_parent_id];
                                                    var show_product = false;
                                                    Object.keys(parent.products).map(function (product_id) {
                                                        var product = parent.products[product_id];
                                                        if (
                                                            product.is_deleted == 0 &&
                                                            product.available != null &&
                                                            product.pricing[self.state.pricing_category].price != null &&
                                                            (localStorage.getItem('staff_id') > 0 || product.is_internal == 0)
                                                        ) {
                                                            show_product = true;
                                                            formats_print += '<span class="badge badge-pill badge-secondary">' + self.state.list.factor[product.product_factor_id].name + '</span> ';
                                                            formats.push(product.product_factor_id);
                                                        }
                                                    });
                                                    if (
                                                        show_product &&
                                                        (self.state.filter.owner == null || self.state.filter.owner == parent.product_owner_id) &&
                                                        (self.state.filter.type == null || self.state.filter.type == parent.product_type_id) &&
                                                        (self.state.filter.brand == null || self.state.filter.brand == parent.product_brand_id) &&
                                                        (self.state.filter.factor == null || parent.factors.indexOf(parseInt(self.state.filter.factor)) > -1)
                                                    ) {
                                                        return (
                                                            <React.Fragment key={key}>
                                                                <tr className={(parent.visible ? "text-bold bg-gray-light" : "")}
                                                                    onClick={this.toggleProductParent.bind(this, product_owner_id, product_parent_id)}>
                                                                    <td className="d-lg-table-cell">{parent.name}</td>
                                                                    <td className="d-md-table-cell">{parent.type_name}</td>
                                                                    <td className="d-md-table-cell">
                                                                        <div dangerouslySetInnerHTML={{__html: formats_print}}/>
                                                                    </td>
                                                                </tr>
                                                                <tr className={(parent.visible ? "" : "d-none")} style={{background: "#fff"}}>
                                                                    <td colSpan={6} className="border-top-0 p-0 ml-1 mr-1">
                                                                        <ProductRows filter={this.state.filter} updateCart={this.updateCart} products={this.state.products}
                                                                                     province={this.state.province} pricing_category={this.state.pricing_category}
                                                                                     pricing_category={pricing_category}
                                                                                     parent_id={parent.product_parent_id} history={this.props.history}
                                                                                     factor={this.state.list.factor} credit={this.state.credit}
                                                                                     product_owner_id={product_owner_id}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                }, this)}
                                            </React.Fragment>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className="card-body">
                        <p className="lead mb-0">Order Summary</p>
                        <div className={Object.keys(this.state.cart.owners).length > 0 || this.state.loading ? "d-none" : "text-center"}>
                            Your cart is empty. Please select items above.{(this.state.cart.owners).length}
                        </div>
                        <div className={Object.keys(this.state.cart.owners).length > 0 ? "table-responsive" : "d-none"}>
                            <table className="table text-right table-sm">
                                <thead className="thead-dark">
                                <tr>
                                    <th className="text-left">Company</th>
                                    <th className="text-left">Items</th>
                                    <th>Subtotal</th>
                                    <th>Tax</th>
                                    <th>Deposit</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(this.state.cart.owners).map(function (owner_id) {
                                    let cart_owner = this.state.cart.owners[owner_id];
                                    console.log(cart_owner.items)
                                    return (
                                        <tr key={owner_id}>
                                            <td className="text-left">
                                                {this.state.list.owner[owner_id].name}
                                            </td>
                                            <td className="text-left">
                                                <ul className="pl-3">
                                                    {Object.keys(cart_owner.items).map(function (product_id) {
                                                        return (
                                                            <li key={product_id}
                                                                    className={(cart_owner.items[product_id].quantity > 0 ? "" : "d-none")}>{cart_owner.items[product_id].quantity}x {this.state.product_names[product_id]}</li>
                                                        )
                                                    }, this)}
                                                    {Object.keys(cart_owner.items).map(function (product_id) {
                                                        return (
                                                            <li key={product_id} className={(cart_owner.items[product_id].quantity_promo > 0 ? "" : "d-none")}><span
                                                                className="text-danger">Promo:</span> {cart_owner.items[product_id].quantity_promo}x {this.state.product_names[product_id]}
                                                            </li>
                                                        )
                                                    }, this)}
                                                </ul>
                                            </td>
                                            <td>
                                                ${cart_owner.subtotal}
                                            </td>
                                            <td>
                                                ${cart_owner.tax}
                                            </td>
                                            <td>
                                                ${cart_owner.deposit}
                                            </td>
                                            <td>
                                                ${cart_owner.total}
                                            </td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                                <tfoot>
                                <tr className="text-bold">
                                    <th colSpan={2}>
                                        TOTAL:
                                    </th>
                                    <th>
                                        ${this.state.cart.subtotal}
                                    </th>
                                    <th>
                                        ${this.state.cart.tax}
                                    </th>
                                    <th>
                                        ${this.state.cart.deposit}
                                    </th>
                                    <th>
                                        ${this.state.cart.total}
                                    </th>
                                </tr>
                                <tr className={(this.state.cart.credit > 0 ? "text-bold text-success" : "d-none")}>
                                    <th colSpan={5}>
                                        Less Account Credit:
                                    </th>
                                    <th>
                                        (${parseFloat(this.state.cart.credit).toFixed(2)})
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan={5} className="text-bold h4">
                                        Total Payable:
                                    </th>
                                    <th className="text-bold h4">
                                        ${parseFloat(this.state.cart.total - this.state.cart.credit).toFixed(2)}
                                    </th>
                                </tr>
                                </tfoot>
                            </table>
                            <div className={(localStorage.getItem('staff_id') > 0 && this.state.cart.num_promo > 0 ? "pb-2" : "d-none")}>
                                <label className="mb-0"><b>Reason for Promo:</b></label>
                                <textarea className="form-control" style={{height: '50px'}} onChange={this.ChangeNotes.bind(this)} value={this.state.notes}></textarea>
                            </div>
                            <label className="mb-0"><b>Delivery Instructions:</b> <span className="text-muted">(optional)</span></label>
                            <textarea className="form-control" placeholder="e.g. Deliver to the side door, after 2pm" style={{height: '50px'}}
                                      onChange={this.ChangeInstructions.bind(this)} value={this.state.delivery_instructions}></textarea>
                            <div className="text-right float-right mt-3" style={{'maxWidth': '500px'}}>
                                <Nav tabs className="justify-content-end">
                                    <NavItem className={(this.state.cart.total - this.state.cart.credit == 0) ? "d-none" : ""}>
                                        <NavLink className={classnames({active: this.state.payment_method === '1'})} onClick={() => {
                                            this.toggleTab('1');
                                        }} style={{cursor: 'pointer'}}>
                                            <span className="d-none d-sm-block">{(this.state.allow_delayed_cc == 0?"Credit Card":"Credit Card, Immediately")}</span><span className="d-block d-sm-none">{(this.state.allow_delayed_cc == 0?"Credit Card":"Credit Card Now")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={(this.state.cart.total - this.state.cart.credit == 0 || this.state.allow_delayed_cc==0) ? "d-none" : ""}>
                                        <NavLink className={classnames({active: this.state.payment_method === '6'})} onClick={() => {
                                            this.toggleTab('6');
                                        }} style={{cursor: 'pointer'}}>
                                            <span className="d-none d-sm-block">Credit Card, On Delivery</span><span className="d-block d-sm-none">Credit Card On Delivery</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={(this.state.cart.total - this.state.cart.credit == 0 || this.state.allow_cod == 0) ? "d-none" : ""}>
                                        <NavLink className={classnames({active: this.state.payment_method === '2'})} onClick={() => {
                                            this.toggleTab('2');
                                        }} style={{cursor: 'pointer'}}>
                                            <span className="d-none d-sm-block">Cheque</span><span className="d-block d-sm-none">Cheque</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.payment_method}>
                                    <TabPane tabId="1" className={(this.state.cart.total - this.state.cart.credit == 0) ? "d-none" : ""}>
                                        <Alert color="danger" className={(this.state.saved_card_id !== "" || this.state.loading) ? 'd-none' : 'text-center'}>
                                            You haven't added a card yet. &nbsp;<Link to="payment-method" className="btn btn-secondary btn-sm">Add One</Link>
                                        </Alert>
                                        <div className={(this.state.saved_card_id === "") ? 'd-none' : ''}>
                                            Order will be charged to <span
                                            className="text-capitalize">{this.state.saved_issuer}</span> {this.state.saved_number} &nbsp;&nbsp;|&nbsp;&nbsp;
                                            <Link to="payment-method">Change Card</Link>
                                        </div>
                                        <Button size="lg" color="primary" className="ml-4 mt-3" disabled={this.state.saved_card_id == "" || this.state.processing}
                                                onClick={this.submitOrder}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Place Order</Button>
                                    </TabPane>
                                    <TabPane tabId="6" className={(this.state.cart.total - this.state.cart.credit == 0 || this.state.allow_delayed_cc==0) ? "d-none" : ""}>
                                        <Alert color="danger" className={(this.state.saved_card_id !== "" || this.state.loading) ? 'd-none' : 'text-center'}>
                                            You haven't added a card yet. &nbsp;<Link to="payment-method" className="btn btn-secondary btn-sm">Add One</Link>
                                        </Alert>
                                        <div className={(this.state.saved_card_id === "") ? 'd-none' : ''}>
                                            Order will be charged to <span
                                            className="text-capitalize">{this.state.saved_issuer}</span> {this.state.saved_number} &nbsp;&nbsp;|&nbsp;&nbsp;
                                            <Link to="payment-method">Change Card</Link>
                                        </div>
                                        <Button size="lg" color="primary" className="ml-4 mt-3" disabled={this.state.saved_card_id == "" || this.state.processing}
                                                onClick={this.submitOrder}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Place Order</Button>
                                    </TabPane>
                                    <TabPane tabId="2" className={(this.state.cart.total - this.state.cart.credit == 0 || this.state.allow_cod == 0) ? "d-none" : ""}>
                                        <div>
                                            Cheques payable to: <b>Equals Brewing Company Inc.</b>
                                        </div>
                                        <Button size="lg" color="primary" className="ml-4 mt-3" disabled={this.state.processing} onClick={this.submitOrder}><i
                                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Place Order</Button>
                                    </TabPane>
                                    <div className={(this.state.cart.total - this.state.cart.credit != 0) ? "d-none" : ""}>
                                        <Button size="lg" color="primary" className="ml-4 mt-3" disabled={this.state.processing} onClick={this.submitOrder}><i
                                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Place Order</Button>
                                    </div>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (PlaceOrder);

class ProductRows extends React.Component {
    constructor(props) {
        super(props);
    }

    IncrementItem = (product_id) => {
        var old_products_list = this.props.products;
        old_products_list[this.props.product_owner_id][this.props.parent_id].products[product_id].quantity++;
        this.setState({products: old_products_list});
        this.UpdateTotal();
    };
    DecreaseItem = (product_id) => {
        var old_products_list = this.props.products;
        old_products_list[this.props.product_owner_id][this.props.parent_id].products[product_id].quantity--;
        this.setState({products: old_products_list});
        this.UpdateTotal();
    };
    ChangeItem = (product_id, event) => {
        if (!isNaN(event.target.value)) {
            var old_products_list = this.props.products;
            old_products_list[this.props.product_owner_id][this.props.parent_id].products[product_id].quantity = event.target.value;
            this.setState({products: old_products_list});
            this.UpdateTotal();
        }
    };
    IncrementItemPromo = (product_id) => {
        var old_products_list = this.props.products;
        old_products_list[this.props.product_owner_id][this.props.parent_id].products[product_id].quantity_promo++;
        this.setState({products: old_products_list});
        this.UpdateTotal();
    };
    DecreaseItemPromo = (product_id) => {
        var old_products_list = this.props.products;
        old_products_list[this.props.product_owner_id][this.props.parent_id].products[product_id].quantity_promo--;
        this.setState({products: old_products_list});
        this.UpdateTotal();
    };
    ChangeItemPromo = (product_id, event) => {
        if (!isNaN(event.target.value)) {
            var old_products_list = this.props.products;
            old_products_list[this.props.product_owner_id][this.props.parent_id].products[product_id].quantity_promo = event.target.value;
            this.setState({products: old_products_list});
            this.UpdateTotal();
        }
    };
    handleFocus = function (event) {
        if (event.target.value == "0") {
            event.target.value = "";
        }
        event.target.select();
    };

    UpdateTotal() {
        let zero = money.floatToAmount(0);
        let products = this.props.products;
        var cart = {
            "owners": {},
            "subtotal": zero,
            "tax": zero,
            "deposit": zero,
            "total": zero,
            "credit": zero,
            "num_promo": 0
        };
        var self = this;
        var num_promo = 0;
        Object.keys(products).map(function (product_owner_id) {
            Object.keys(products[product_owner_id]).map(function (product_parent_id) {
                let product_list = products[product_owner_id][product_parent_id].products;
                let owner_id = product_owner_id;
                Object.keys(product_list).map(function (product_id) {
                    let product = product_list[product_id];
                    if (product.quantity <= 0 || isNaN(product.quantity)) {
                        products[product_owner_id][product_parent_id].products[product_id].quantity = 0;
                    } else if (product.quantity > product.available) {
                        products[product_owner_id][product_parent_id].products[product_id].quantity = product.available;
                        Swal("Sorry, we're running low on that", "You can only order " + product.available + " units of " + product.name + ".", "error");
                    }
                    if (product.quantity_promo < 0 || isNaN(product.quantity_promo)) {
                        products[product_owner_id][product_parent_id].products[product_id].quantity_promo = 0;
                    } else if (product.quantity_promo > product.available) {
                        products[product_owner_id][product_parent_id].products[product_id].quantity_promo = product.available;
                        Swal("Sorry, we're running low on that", "You can only order " + product.available + " units of " + product.name + ".", "error");
                    }
                    let quantity = money.floatToAmount(products[product_owner_id][product_parent_id].products[product_id].quantity);
                    let quantity_promo = money.floatToAmount(products[product_owner_id][product_parent_id].products[product_id].quantity_promo);
                    if (!money.isZero(quantity) || !money.isZero(quantity_promo)) {
                        if (typeof cart.owners[owner_id] == "undefined") {
                            cart.owners[owner_id] = {
                                "items": {},
                                "subtotal": zero,
                                "tax": zero,
                                "deposit": zero,
                                "total": zero,
                                "count": 0,
                                "count_promo": 0
                            };
                        }
                        let subtotal = money.mul(quantity, money.floatToAmount(product.pricing[self.props.pricing_category].price));
                        var tax_rate = (product.pricing[self.props.pricing_category].is_taxable ? .13 : 0);
                        if (self.props.province == "NS") {
                            tax_rate = (product.pricing[self.props.pricing_category].is_taxable ? .15 : 0);
                        }
                        let tax = money.mul(subtotal, money.floatToAmount(tax_rate));
                        var deposit = money.mul(quantity, money.floatToAmount(product.pricing[self.props.pricing_category].deposit));
                        if (quantity_promo > 0 && product.pricing[self.props.pricing_category].deposit > 5) {
                            var deposit_promo = money.mul(quantity_promo, money.floatToAmount(product.pricing[self.props.pricing_category].deposit));
                            deposit = money.add(deposit, deposit_promo);
                            cart['num_promo']++;
                        }
                        let total = money.add(subtotal, money.add(deposit, tax));
                        if (quantity > 0 || quantity_promo > 0) {
                            cart.owners[owner_id].items[product_id] = {
                                "quantity": Math.round(quantity),
                                "quantity_promo": Math.round(quantity_promo),
                                "subtotal": subtotal,
                                "tax": tax,
                                "deposit": deposit,
                                "total": total,
                            };
                            cart.owners[owner_id].subtotal = money.add(cart.owners[owner_id].subtotal, subtotal);
                            cart.owners[owner_id].tax = money.add(cart.owners[owner_id].tax, tax);
                            cart.owners[owner_id].deposit = money.add(cart.owners[owner_id].deposit, deposit);
                            cart.owners[owner_id].total = money.add(cart.owners[owner_id].total, total);
                            cart.owners[owner_id].count = Math.round(quantity + cart.owners[owner_id].count);
                            cart.owners[owner_id].count_promo = Math.round(quantity_promo + cart.owners[owner_id].count_promo);
                            cart.subtotal = money.add(cart.subtotal, subtotal);
                            cart.tax = money.add(cart.tax, tax);
                            cart.deposit = money.add(cart.deposit, deposit);
                            cart.total = money.add(cart.total, total);
                        }
                    }
                });
            });
        });
        if (this.props.credit > 0) {
            if (cart.total > this.props.credit) {
                cart.credit = this.props.credit;
                // cart.total = money.sub(cart.total, money.floatToAmount(this.props.credit));
            } else {
                cart.credit = cart.total;
                //cart.total = money.floatToAmount(0);
            }
        }
        this.props.updateCart(cart);
    }

    render() {
        var self = this;
        var product_list = [];
        Object.keys(this.props.products[this.props.product_owner_id][this.props.parent_id].products).map(function (product_id, key) {
            var x = self.props.products[self.props.product_owner_id][self.props.parent_id].products[product_id];
            x['product_id'] = product_id;
            product_list.push(x)
        });
        product_list.sort((a, b) => parseFloat(a.hl) - parseFloat(b.hl));


        return (
            <div key={this.props.parent_id} className="ml-3">
                <div
                    className={this.props.products[this.props.product_owner_id][this.props.parent_id].description == null ? "d-none" : "pb-1 pt-1 font-italic"}>{this.props.products[this.props.product_owner_id][this.props.parent_id].description}</div>
                <table className="table table-sm border-bottom table-borderless bg-white">
                    <thead className="bg-light">
                    <tr>
                        <th width={(localStorage.getItem('staff_id') > 0 ? "30%" : "30%")}>SKU Name</th>
                        <th width={(localStorage.getItem('staff_id') > 0 ? "10%" : "15%")}>Format</th>
                        <th width={(localStorage.getItem('staff_id') > 0 ? "10%" : "15%")}>Price</th>
                        <th width={(localStorage.getItem('staff_id') > 0 ? "10%" : "15%")}>Deposit</th>
                        <th width={(localStorage.getItem('staff_id') > 0 ? "20%" : "25%")}>{(localStorage.getItem('active_account') > 0 ? "Regular" : "Quantity")}</th>
                        <th width={(localStorage.getItem('staff_id') > 0 ? "20%" : "0%")} className={(localStorage.getItem('staff_id') > 0 ? "text-danger" : "d-none")}>Promo</th>
                    </tr>
                    </thead>
                    <tbody>
                    {product_list.map(function (product, key) {
                        var product_id = product['product_id'];
                        var thumb = "/img/products/" + product_id + "_sm.png";
                        var url = "/img/products/" + product_id + ".jpg";
                        if (
                            product.available != null &&
                            product.is_deleted == 0 &&
                            product.pricing[self.props.pricing_category].price != null &&
                            (self.props.filter.factor == null || self.props.filter.factor == product.product_factor_id) &&
                            (localStorage.getItem('staff_id') > 0 || product.is_internal == 0)
                        ) {
                            return (
                                <tr key={key}>
                                    <td className="align-middle">
                                        <a href={url} data-lity className={(localStorage.getItem('staff_id') > 0 ? "d-none" : "d-none d-sm-inline mr-2")}>
                                            <img src={thumb} style={{width: "40px", height: "45px"}}></img>
                                        </a>
                                        {product.name}</td>
                                    <td className="align-middle">{self.props.factor[product.product_factor_id].name}</td>
                                    <td className="align-middle">${product.pricing[self.props.pricing_category].price.toFixed(2)}</td>
                                    <td className="align-middle">${product.pricing[self.props.pricing_category].deposit.toFixed(2)}</td>
                                    <td className="align-middle">
                                        <div className={(product.available > 0) ? "d-none" : ""}><i>Coming Soon</i></div>
                                        <div className={(product.available > 0) ? "input-group input-group-sm" : "d-none"}>
                                            <div className="input-group-prepend d-none d-sm-block" style={{cursor: "pointer"}}
                                                 onClick={self.DecreaseItem.bind(self, product_id)}>
                                                <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">-</span>
                                            </div>
                                            <input type="tel" className={(product.quantity > 0 ? "form-control text-bold" : "form-control")}
                                                   value={product.quantity} onFocus={self.handleFocus} onChange={self.ChangeItem.bind(self, product_id)}
                                                   style={{maxWidth: '60px'}}></input>
                                            <div className="input-group-append d-none d-sm-block" style={{cursor: "pointer"}}
                                                 onClick={self.IncrementItem.bind(self, product_id)}>
                                                <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">+</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={(localStorage.getItem('staff_id') > 0 ? "align-middle" : "d-none")}>
                                        <div className={(product.available > 0) ? "d-none" : ""}><i>Coming Soon</i></div>
                                        <div className={(product.available > 0) ? "input-group input-group-sm" : "d-none"}>
                                            <div className="input-group-prepend d-none d-sm-block" style={{cursor: "pointer"}}
                                                 onClick={self.DecreaseItemPromo.bind(self, product_id)}>
                                                <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">-</span>
                                            </div>
                                            <input type="tel" className={(product.quantity_promo > 0 ? "form-control text-bold" : "form-control")}
                                                   value={product.quantity_promo} onFocus={self.handleFocus} onChange={self.ChangeItemPromo.bind(self, product_id)}
                                                   style={{maxWidth: '60px'}}></input>
                                            <div className="input-group-append d-none d-sm-block" style={{cursor: "pointer"}}
                                                 onClick={self.IncrementItemPromo.bind(self, product_id)}>
                                                <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">+</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    }, this)}
                    </tbody>
                </table>
            </div>
        );
    }
}