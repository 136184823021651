/*!
 *
 * Angle - Bootstrap Admin App + ReactJS
 *
 * Version: 3.8.9.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {API_ROOT} from './api-config';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter(o => o.async = true);


class App extends Component {
    //login
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            staff_id: null,
            first_name: '',
            last_name: '',
            active_account: localStorage.getItem('active_account'),
            accounts: {}
        };
        this.refresh_from_local = this.refresh_from_local.bind(this)
        this.updateActiveAccount = this.updateActiveAccount.bind(this)
    }

    async componentDidMount() {
        this.userHasAuthenticated(false);
        var self = this;
        self.refresh_from_local(); // trigger immediate reload from local storage
        if (localStorage.getItem('first_name') !== null) {
            axios.defaults.withCredentials = true;
            axios.post(`${API_ROOT}/heartbeat`, {withCredentials: true})
                .then(function (response) {
                    self.userHasAuthenticated(true);
                    self.setState({isAuthenticating: false});
                    self.refresh_from_local()
                })
                .catch(function (error) {
                    self.userSignOut()
                    self.setState({isAuthenticating: false});
                });
        } else {
            self.userSignOut()
            this.setState({isAuthenticating: false});
        }
    }

    refresh_from_local() {
        this.setState({
            first_name: localStorage.getItem('first_name'),
            last_name: localStorage.getItem('last_name'),
            staff_id: localStorage.getItem('staff_id'),
            accounts: JSON.parse(localStorage.getItem('accounts'))
        });
        if (localStorage.getItem('active_account') == null && localStorage.getItem('accounts') !== null) {
            var tmp = JSON.parse(localStorage.getItem('accounts'));
            this.setState({active_account: tmp[0]['account_id']});
        }
    }

    userHasAuthenticated = authenticated => {
        this.setState({isAuthenticated: authenticated});
    }
    userSignOut = event => {
        axios.defaults.withCredentials = true;
        axios.post(`${API_ROOT}/logout`, {withCredentials: true})
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('staff_id');
        localStorage.removeItem('accounts');
        this.userHasAuthenticated(false);
    }

    updateActiveAccount(account_id) {
        this.setState({active_account: account_id});
        localStorage.setItem('active_account', account_id);
    }

    render() {

        // specify base href from env varible 'WP_BASE_HREF'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global WP_BASE_HREF */
        const basename = '/';

        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            userSignOut: this.userSignOut,
            staff_id: this.state.staff_id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            accounts: this.state.accounts,
            active_account: this.state.active_account,
            refresh_from_local: this.refresh_from_local,
            updateActiveAccount: this.updateActiveAccount,
        };


        return (
            !this.state.isAuthenticating &&
            <BrowserRouter basename={basename}>
                <Routes childProps={childProps}/>
            </BrowserRouter>
        );
    }
}

export default App;
