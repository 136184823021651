import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button, Alert} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import PaymentStatus from '../Order/PaymentStatus';

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.active_account !== prevProps.active_account) {
            this.getOrders();
        }
    }

    async componentDidMount() {
        document.title = "Order History | Equals Brewing";
        this.getOrders();
    }

    getOrders() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/orders/' + this.props.active_account)
            .then(function (response) {
                self.setState({
                    orders: response.data,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Order History</div>
                </div>
                <Alert color="danger" className={(this.state.orders.length == 0 && !this.state.loading) ? 'text-center' : 'd-none'}>
                    You haven't placed an order yet. &nbsp;<Link to="place-order" className="btn btn-secondary btn-sm">Place One Now</Link>
                </Alert>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className="card-header">
                        <div className="card-title">{this.state.orders.length} Previous Orders</div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-pointer">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Kegs</th>
                                    <th>Cases</th>
                                    <th>Total</th>
                                    <th className="d-none d-sm-table-cell">Payment Method</th>
                                    <th className="d-none d-sm-table-cell">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.orders.map(function (item, key) {
                                    return (
                                        <tr onClick={this.goToOrder.bind(this, item.order_id)}>
                                            <td>{item.date_full}</td>
                                            <td>{item.kegs}</td>
                                            <td>{item.cases}</td>
                                            <td>${(item.is_paid == 5 ? "0.00" : item.total.toFixed(2))}</td>
                                            <td className="d-none d-sm-table-cell">{(item.payment_method == 1 || item.payment_method == 6 ? "Credit Card" : (item.payment_method == 3 ? "EFT" : "Payment on Delivery"))}</td>
                                            <td className="d-none d-sm-table-cell">
                                                <PaymentStatus is_paid={item.is_paid} void_date={item.void_date}/>
                                            </td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (OrderHistory);
