import React from 'react';
import {Link} from 'react-router-dom';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button} from 'reactstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import {API_ROOT} from '../../api-config';

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orders: []
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.active_account !== prevProps.active_account) {
            this.getOrders();
        }
    }

    async componentDidMount() {
        document.title = "Dashboard | Equals Brewing";
        var self = this;
        if (localStorage.getItem('show_welcome') == "true") {
            localStorage.setItem('show_welcome', false);
            Swal({
                title: "Welcome! You're ready to go",
                html: '<br><p>With a few clicks you can now order product instantly over your computer, tablet, or phone. Easy-Peasy!</p>',
                showCloseButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Add Credit Card / Visa Debit',
                cancelButtonText: 'Place Order',
                showCancelButton: true
            }).then((setupPayment) => {
                if (typeof setupPayment.dismiss == 'undefined') {
                    self.props.history.push("/payment-method");
                } else if (setupPayment.dismiss == "cancel") {
                    self.props.history.push("/place-order");
                }
            });
        }
        this.getOrders();
    }

    getOrders() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/orders/' + this.props.active_account + '/limit/5')
            .then(function (response) {
                self.setState({
                    orders: response.data
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    self.setState({
                        orders: []
                    });
                }
            });
    }

    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Dashboard
                    </div>
                </div>
                <Row>
                    <Col>
                        <div className="card card-default">
                            <div className="card-header">
                                <div className="card-title">Welcome to Our Licensee Portal</div>
                            </div>
                            <div className="card-body">
                                <p>You can order all our products from here along with whatever bar tools you need.</p>
                                <p>Simply click the “Place an Order" button.</p>
                                <p>We appreciate your business!</p>
                            </div>
                        </div>
                    </Col>
                    <Col className="min500" sm={12}>
                        <div className="card">
                            <Link to="place-order" className="btn btn-primary btn-xl btn-block">
                                <em className="icon-basket"></em> Place an Order
                            </Link>
                        </div>
                        <div className={(this.state.orders.length == 0) ? 'd-none' : 'card card-default'}>
                            <div className="card-header">
                                <div className="card-title">Most Recent {this.state.orders.length} Orders</div>
                            </div>
                            <div className="card-body">
                                {/* START table responsive */}
                                <div className="table-responsive">
                                    <table className="table table-hover table-pointer">
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Kegs</th>
                                            <th>Cases</th>
                                            <th>Amount</th>
                                            <th className="d-none d-sm-table-cell">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.orders.map(function (item, key) {
                                            return (
                                                <tr onClick={this.goToOrder.bind(this, item.order_id)}>
                                                    <td>{item.date_short}</td>
                                                    <td>{item.kegs}</td>
                                                    <td>{item.cases}</td>
                                                    <td>${item.total.toFixed(2)}</td>
                                                    <td className="d-none d-sm-table-cell">
                                                        <span className="badge badge-purple">Ordered</span>
                                                    </td>
                                                </tr>
                                            )
                                        }, this)}
                                        </tbody>
                                    </table>
                                </div>
                                {/* END table responsive */}
                            </div>
                            <div className="card-footer text-center">
                                <Link to="orders" className="text-sm" href="/orders">View All Orders</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

export default (Dashboard);