import React, {Component} from 'react';

class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>&copy; {year} Equals Brewing Company Inc.</span>
                <span className="powered-by">powered by <a href="http://www.thewebpeople.ca" target="_blank" rel="noopener noreferrer">The Web People</a></span>
            </footer>
        );
    }

}

export default Footer;
