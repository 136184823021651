import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';

class Support extends React.Component {

    compontentDidMount() {
        document.title = "Support | Equals Brewing";
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Support</div>
                </div>
                <div className="text-center">
                    <h3>Need help? Call or email us 24/7</h3>
                    <h4>
                        <p><b>Toll-Free:</b> 1-877-378-2571</p>
                        <p><b>By Email:</b> <a href="mailto:orders@equalsbrewing.com">orders@equalsbrewing.com</a></p>
                    </h4>
                </div>
            </ContentWrapper>
        );
    }
}

export default (Support);