import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Card, CardBody, CardHeader, Input} from 'reactstrap';
import MaskedInput from 'react-maskedinput'
import Cards from 'react-credit-cards';
import 'react-credit-cards/lib/styles-compiled.css';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'


class PaymentMethod extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            number: '',
            name: '',
            expiry: '',
            cvc: '',
            focused: '',
            saved_number: '',
            saved_name: '',
            saved_expiry: '',
            saved_issuer: '',
            saved_card_id: '',
            loading: true
        };
        this.addPayment = this.addPayment.bind(this);
    }

    handleInputFocus = ({target}) => {
        this.setState({
            focused: target.name,
        });
    };

    handleInputChange = ({target}) => {
        if (target.name === 'number') {
            this.setState({
                [target.name]: target.value.replace(/ /g, ''),
            });
        } else if (target.name === 'expiry') {
            this.setState({
                [target.name]: target.value.replace(/ |\//g, ''),
            });
        } else {
            this.setState({
                [target.name]: target.value,
            });
        }
    };


    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.active_account !== prevProps.active_account) {
            this.getSavedCC();
        }
    }


    componentDidMount() {
        document.title = "Payment Method | Equals Brewing";
        this.getSavedCC();
    }

    getSavedCC() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + this.props.active_account + '/saved_card')
            .then(function (response) {
                self.setState({
                    saved_name: response.data.name,
                    saved_number: '************' + response.data.last_four,
                    saved_expiry: response.data.expiry_month + "/" + response.data.expiry_year,
                    saved_issuer: response.data.card_type,
                    saved_card_id: response.data.card_id,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    addPayment = (props) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/account/' + this.props.active_account + '/saved_card', {
            number: this.state.number,
            name: this.state.name,
            expiry: this.state.expiry,
            cvc: this.state.cvc
        })
            .then(function (response) {
                var last_four = self.state.number.toString().slice(-4);
                self.setState({
                    number: '',
                    name: '',
                    expiry: '',
                    cvc: '',
                    focused: '',
                    saved_name: self.state.name,
                    saved_number: '************' + last_four,
                    saved_expiry: self.state.expiry,
                    saved_issuer: response.data.card_type,
                    saved_card_id: response.data.card_id,
                });
                document.getElementById("add-card").reset();
                document.getElementById("expiry").value = "";

            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    deletePayment = (props) => {
        var self = this;
        Swal({
            title: "Are you sure?",
            text: "You will have to manually enter a new card before you can place another order.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.value) {
                axios.defaults.withCredentials = true;
                axios.delete(API_ROOT + '/account/' + self.props.active_account + '/saved_card/' + self.state.saved_card_id)
                    .then(function (response) {
                        self.setState({
                            saved_name: '',
                            saved_number: '',
                            saved_expiry: '',
                            saved_issuer: '',
                            saved_card_id: ''
                        });
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                        }
                    });
            }
        })
    }


    render() {

        const {name, number, expiry, cvc, focused, saved_number, saved_name, saved_expiry, saved_issuer, saved_card_id} = this.state;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Payment Method</div>
                </div>
                <div className="text-center">
                    A saved payment method makes ordering fast and easy.<p>
                    Saving a payment method also allows you to place orders over the phone and enables other authorized employees to order product for your account.</p>
                </div>
                <Row className="justify-content-md-center">
                    <Col md={12} lg={6} className={(saved_card_id === "") ? 'd-none' : 'text-center'}>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardHeader>
                                <div className="card-title">Current Saved Payment Method</div>
                            </CardHeader>
                            <CardBody>

                                {(saved_card_id === "") ? null :
                                    <Cards
                                        name={saved_name}
                                        number={saved_number}
                                        expiry={saved_expiry}
                                        issuer={saved_issuer}
                                        cvc='xxx'
                                        preview={true}
                                    />
                                }
                                <div className="text-center m-3">
                                    <button className={(saved_card_id !== "") ? 'btn btn-danger btn-lg' : 'd-none'} type="text" onClick={this.deletePayment}>Delete Payment Method
                                    </button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className={(saved_card_id === "") ? 'col-md-12 col-lg-6' : 'col-md-12 col-lg-6'}>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardHeader>
                                <div className="card-title">Add Saved Payment Method</div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <div className="col-xl-auto" style={{minWidth: '50%'}}>
                                        <Cards
                                            number={number}
                                            name={name}
                                            expiry={expiry}
                                            focused={focused}
                                            cvc={cvc}
                                            callback={this.handleCallback}
                                        />
                                    </div>
                                    <div className="col-xl-auto">
                                        <form id="add-card">
                                            <Input
                                                type="tel"
                                                className="form-control m-1"
                                                name="number"
                                                placeholder="Card Number"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                            <input
                                                type="text"
                                                className="form-control m-1"
                                                name="name"
                                                placeholder="Name"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                            <MaskedInput
                                                type="tel"
                                                className="form-control m-1"
                                                name="expiry"
                                                placeholder="Expiry (MM/YY)"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                                mask="11/11"
                                                id="expiry"
                                            />
                                            <input
                                                type="tel"
                                                name="cvc"
                                                className="form-control m-1"
                                                placeholder="CVC"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                        </form>
                                    </div>
                                </Row>
                                <div className="text-center m-3">
                                    <button className="btn btn-primary btn-lg" type="text" onClick={this.addPayment}>Save as Payment Method</button>
                                </div>
                                <p>
                                    &bull; You can delete your payment method at any time.<br></br>
                                    &bull; Your saved payment method can be used by other authorized users to place orders for your account.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

export default (PaymentMethod);
